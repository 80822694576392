<template>
  <div>
    <button @click.stop.prevent="generateCode()" class="btn">
      <h4 v-if="!uniqueCode">Generate log in code</h4>
      <h4 v-else>Code: {{ uniqueCode }}</h4>
    </button>
    <h4 v-if="uniqueCode">This code is valid for {{ timeConversion }}</h4>
  </div>
</template>

<script>
import { api } from "@/helpers/api";

export default {
  name: "CodeLogIn",

  data() {
    return {
      uniqueCode: false,
      timer: 0,
      reset: null,
    };
  },
  computed: {
    timeConversion() {
      const seconds = this.timer % 60;
      return `${seconds} seconds`;
    },
  },
  methods: {
    countDownTimer() {
      this.timer = 60;
      this.reset = setInterval(() => {
        this.timer--;
        if (this.timer === 0) {
          clearInterval(this.reset);
          this.$emit("update-timer", this.timer);
          this.uniqueCode = false;
        }
      }, 1000);
    },

    async generateCode() {
      this.countDownTimer();
      this.uniqueCode = parseInt(Math.random() * (999999 - 100001) + 100001);
      let company = localStorage.getItem("company");
      let site = localStorage.getItem("site");
      let station = localStorage.getItem("station");
      let data = {
        company: company,
        site: site,
        station: station,
        code: this.uniqueCode,
      };
      await api.add_device_code(data);
      // console.log(await api.device_login({ code: this.uniqueCode }));
    },
  },
  mounted() {
    // Code to run when the component is mounted
  },
};
</script>

<style scoped>
.btn {
  display: flex;
  justify-content: center;
  margin: 0.5rem;
  min-width: 15rem;
  h4 {
    margin: 1rem 0.5rem;
  }
}
</style>

