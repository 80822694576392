<template>
  <Authenticated>
    <div class="actions">
      <h2>Settings for {{ site }}</h2>
    </div>

    <div class="payment_options" v-if="site">
      <form @submit.prevent>
        <h4>Display Theme</h4>
        <Theme />

        <h4>
          Website Controls for {{ site }}
          <button
            class="save-btn"
            @click="
              updateSettings([siteSettings, localSettings]);
              saveSiteSettings();
              openCloseSite();
            ">
            {{ save_btn_text }}
          </button>
        </h4>
        <ul class="grid wrapper">
          <li class="modifierBox">
            <p>Close Website</p>
            <Toggle class="toggle" onLabel="Closed" offLabel="Open" v-model="siteSettings.openClose" :value="siteSettings.openClose" />
          </li>
          <li class="modifierBox">
            <p>Region</p>
            <p v-if="getCurrentRegion">{{ getCurrentRegion[0].name }}</p>
            <select name="region" id="opening-hours" class="dropdown" v-model="selectedRegion">
              <option value="" disabled>Change region</option>
              <option v-for="region in getRegionNames" :key="region" :value="region">
                {{ region }}
              </option>
            </select>
          </li>
          <li>
            <button @click="openOperatingHours = !openOperatingHours" class="hours-btn"><font-awesome-icon icon="fa-plus" /> <br />Add Operating Hours</button>
          </li>

          <OperatingHoursModal
            @saveHours="saveOpeningHours"
            @showOperatingHoursModal="openOperatingHours = !openOperatingHours"
            @updateYear="setYear"
            :week="weekFinal"
            :holidays="holidayFinal"
            :thisYear="thisYear"
            :nextYear="nextYear"
            :currentYear="currentYear"
            :open="openOperatingHours" />
        </ul>

        <h4>
          Checkout Options (Site Wide)
          <button
            class="save-btn"
            @click="
              updateSettings([siteSettings, localSettings]);
              saveSiteSettings();
            ">
            {{ save_btn_text }}
          </button>
        </h4>
        <ul class="grid wrapper">
          <li class="modifierBox">
            <p>Require order name?</p>
            <Toggle class="toggle" onLabel="On" offLabel="Off" v-model="siteSettings.nameRequired" :value="siteSettings.nameRequired" />
          </li>
          <li class="modifierBox">
            <p>Allow order comments?</p>
            <Toggle class="toggle" onLabel="On" offLabel="Off" v-model="siteSettings.notesRequired" :value="siteSettings.notesRequired" />
          </li>
          <li class="modifierBox">
            <p>Allow Images?</p>
            <Toggle class="toggle" onLabel="On" offLabel="Off" v-model="siteSettings.imagesRequired" :value="siteSettings.imagesRequired" />
          </li>
          <li class="modifierBox">
            <p>Future Orders?</p>
            <Toggle class="toggle" onLabel="On" offLabel="Off" v-model="siteSettings.futurePickup" :value="siteSettings.futurePickup" />
          </li>
          <li class="modifierBox">
            <p>Custom Price Products?</p>
            <Toggle class="toggle" onLabel="On" offLabel="Off" v-model="siteSettings.customPrice" :value="siteSettings.customPrice" />
          </li>
        </ul>

        <h4>Companion</h4>
        <ul class="grid wrapper">
          <li class="modifierBox">
            <CodeLogin />
          </li>
          <li class="modifierBox">
            <button @click.stop.prevent="logoutCompanion" class="btn">Logout Companion</button>
          </li>
        </ul>

        <h4>
          Payment Options (Site Wide)
          <button
            class="save-btn"
            @click="
              updateSettings([siteSettings, localSettings]);
              saveSiteSettings();
            ">
            {{ save_btn_text }}
          </button>
        </h4>
        <ul class="grid wrapper">
          <li class="modifierBox">
            <p>Allow EFTPOS?</p>
            <Toggle class="toggle" onLabel="On" offLabel="Off" v-model="siteSettings.eftpos_pay" :value="siteSettings.eftpos_pay" />
          </li>
          <li class="modifierBox">
            <p>Allow Post Pay?</p>
            <Toggle class="toggle" onLabel="On" offLabel="Off" v-model="siteSettings.post_pay" :value="siteSettings.post_pay" />
          </li>
          <li class="modifierBox">
            <p>Allow Cash?</p>
            <Toggle class="toggle" onLabel="On" offLabel="Off" v-model="siteSettings.cash_pay" :value="siteSettings.cash_pay" />
          </li>
          <li class="modifierBox">
            <p>Allow Refunds?</p>
            <Toggle class="toggle" onLabel="On" offLabel="Off" v-model="siteSettings.refund_allowed" :value="siteSettings.refund_allowed" />
          </li>
          <li class="modifierBox">
            <p>Surcharge?</p>
            <Toggle class="toggle" onLabel="On" offLabel="Off" v-model="siteSettings.surcharge" :value="siteSettings.surcharge" />
          </li>
          <li class="modifierBox">
            <p>Accounts?</p>
            <Toggle class="toggle" onLabel="On" offLabel="Off" v-model="siteSettings.accounts" :value="siteSettings.accounts" />
          </li>
          <li class="modifierBox">
            <p>Vouchers?</p>
            <Toggle class="toggle" onLabel="On" offLabel="Off" v-model="siteSettings.vouchers" :value="siteSettings.vouchers" />
          </li>
          <li class="modifierBox">
            <p>Centrapay?</p>
            <Toggle class="toggle" onLabel="On" offLabel="Off" v-model="siteSettings.centrapay" :value="siteSettings.centrapay" />
          </li>
        </ul>
        <Discounts :channel="channel" />

        <h4>Delivery Options:</h4>
        <ul class="grid wrapper">
          <li class="modifierBox" v-for="(option, idx) in siteSettings.delivery_options" :key="idx">
            <h3 class="del-option">{{ option }}</h3>
            <span @click.stop.prevent="removeDeliveryOption(idx)"><font-awesome-icon icon="fa-xmark" /></span>
          </li>
          <div class="modifierBox">
            <select name="delivery" class="dropdown" v-model="newDelivery">
              <option value="" selected>Select a delivery option</option>
              <option :value="'takeaway'">takeaway</option>
              <option :value="'dine-in'">dine-in</option>
            </select>
            <button @click.stop.prevent="addDeliveryOption" class="btn">Add delivery option</button>
          </div>
        </ul>

        <h4>
          Printers:
          <button @click.stop.prevent="savePrinter" class="save-btn">Save Printer</button>
        </h4>
        <ul class="printer-section">
          <div class="find-printer">
            <button @click.stop.prevent="searchForPrinter" class="btn">Find Printers</button>
            <p v-if="labelError.error" class="red">Please Select a label size to save printer.</p>
          </div>
          <div v-if="printerFound" class="printer-list">
            <select @change="newPrinter($event)" name="printers" id="" class="dropdown">
              <option value="" selected>Select a Printer</option>
              <option :value="p" v-for="p in printers" :key="p">
                <div class="printer">
                  <p class="blue">Model: {{ p[1] }}</p>
                  <p class="blue">IP address: {{ p[0] }}</p>
                </div>
              </option>
            </select>
          </div>
          <div v-if="printer" class="selected-printer">
            <div class="cur-printer">
              <p>Selected Printer:</p>
              <p class="blue">Model: {{ printer.model }}</p>
              <p class="blue">IP: {{ printer.ip }}</p>
              <p v-if="printer.label != 'Select...'" class="blue">Label: {{ printer.label }}</p>
            </div>
            <div class="label">
              <p>Change Label Size</p>
              <select id="labels" class="dropdown">
                <option disabled selected>Select...</option>
                <option v-for="label in getLabels" :value="label" :key="label">
                  {{ label }}
                </option>
              </select>
            </div>
          </div>
        </ul>

        <h4>
          Station Settings:
          <button @click="saveEFTPOSSettings" class="save-btn">
            {{ save_pos_btn_text }}
          </button>
        </h4>
        <ul class="grid wrapper eftpos">
          <li class="modifierBox">
            <div class="siteSettings">
              <p>EFTPOS settings(NZ only):</p>
              <div v-if="eftposSettings.length">
                <label for="eftpos_ip">IP</label>
                <input type="text" name="eftpos" id="eftpos_ip" v-model="eftposSettings[0].ip" />
                <label for="eftpos_port">Port</label>
                <input type="text" name="eftpos" id="eftpos_port" v-model="eftposSettings[0].port" />
              </div>
            </div>
          </li>

          <li class="modifierBox float">
            <p>Float?</p>
            <Toggle class="toggle" onLabel="On" offLabel="Off" @change="saveFloat" />
          </li>
          <li class="modifierBox float">
            <button @click.stop.prevent="discoverReaders">Bluey scan</button>
          </li>
          <li class="modifierBox float">
            <button @click.stop.prevent="connectReader">Connect</button>
          </li>
        </ul>
      </form>
    </div>
    <div v-if="loadingPrinters" class="loading">
      <LoadingSpinner :spinnerMessage="'Searching for Printers...'" />
    </div>
  </Authenticated>
</template>

<script>
import Discounts from "@/components/Discounts/Discounts.vue";
import Authenticated from "@/components/_layouts/Authenticated";
import { mapActions, mapGetters } from "vuex";
import createdMixin from "@/components/_mixins/createdMixin";
import Toggle from "@vueform/toggle";
import Theme from "./Theme.vue";
import OperatingHoursModal from "./OperatingHoursModal.vue";
import holidays2024 from "../../../public/holidays.json";
import holidays2025 from "../../../public/holidays2025.json";
import LoadingSpinner from "@/components/_layouts/LoadingSpinner.vue";
import CodeLogin from "./CodeLogIn.vue";
// import { splice } from "core-js/core/array";
//import dayjs from "dayjs";

export default {
  name: "Settings",
  mixins: [createdMixin],
  components: {
    Authenticated,
    Discounts,
    Toggle,
    Theme,
    OperatingHoursModal,
    LoadingSpinner,
    CodeLogin,
  },
  data() {
    return {
      // float: "float" in JSON.parse(localStorage.getItem("localSettings"))[0] ? JSON.parse(localStorage.getItem("localSettings"))[0].float : false,
      currentFloat: null,
      channel: null,
      station: window.localStorage.getItem("station"),
      site: window.localStorage.getItem("site"),
      eftposIP: null,
      eftposPort: null,
      save_btn_text: "Save",
      save_pos_btn_text: "Save",
      darkMode: false,
      lightMode: false,
      osDefault: true,
      selectedRegion: "",
      openClose: false,
      openOperatingHours: false,
      thisYear: new Date(Date.now()).getFullYear(),
      nextYear: new Date(Date.now()).getFullYear() + 1,
      currentYear: new Date(Date.now()).getFullYear(),
      holidays: [],
      holidayFinal: [],
      weekFinal: [],
      daysOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
      newDelivery: "",
      printers: [],
      printerSelected: false,
      printerFound: false,
      printerNotFound: false,
      printer: {},
      labelSize: "",
      printerDone: true,
      loadingPrinters: false,
      labelError: {
        note: "",
        error: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      getClosed: "getClosed",
      site: "site",
      siteSettings: "siteSettings",
      localSettings: "localSettings",
      eftposSettings: "eftposSettings",
    }),

    holidayObj() {
      return this.natHolidays.map((day) => {
        const dayOpen = {
          open: false,
          openTime: "",
          closeTime: "",
          name: "",
          date: "",
          regional: false,
        };
        dayOpen.name = day.HolidayName;
        dayOpen.date = day.ActualDate;
        dayOpen.regional = day.Regional;
        return dayOpen;
      });
    },

    weekObj() {
      return this.daysOfWeek.map((day) => {
        const dayOpen = { open: false, openTime: "", closeTime: "", name: "" };
        dayOpen.name = day;
        return dayOpen;
      });
    },

    natHolidays() {
      const nats = this.holidays.filter((hol) => hol.Type == "National");
      nats.push(new Proxy({ HolidayName: "", ActualDate: "", Regional: true }, {}));
      return nats;
    },

    getRegionNames() {
      return this.getRegions.map((holiday) => {
        let region = holiday.HolidayName.split(" ").splice(0, holiday.HolidayName.split(" ").length - 2);
        return region.join(" ");
      });
    },

    getRegions() {
      return this.holidays.filter((hol) => hol.Type == "Regional");
    },

    getCurrentRegion() {
      return "hours" in this.siteSettings ? this.siteSettings.hours["holiday"+this.thisYear].filter((day) => day.regional) : false;
    },

    getLabels() {
      if (window.Android && window.Android.getLabels) {
        return JSON.parse(window.Android.getLabels());
      } else {
        return [];
      }
    },
  },
  async mounted() {
    this.$store.commit("currPage", "settings");
    this.setYear(this.thisYear);
    this.holidayFinal = "hours" in this.siteSettings ? this.siteSettings.hours.holiday : this.holidayObj;
    this.weekFinal = "hours" in this.siteSettings ? this.siteSettings.hours.week : this.weekObj;

    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.stripeHandler) {
      window.webkit.messageHandlers.stripeHandler.postMessage("discoverReaders");
    }
    let result = JSON.parse(window.localStorage.getItem("printer"));
    if (result != null) {
      this.printer = result;
      this.printerSelected = true;
    }
  },

  beforeMount() {
    window["Beamy"] = {
      components: this,
      stripeTerminals: (msg) => console.log(msg),
      printerSearchResult: (printers) => this.listPrinters(printers),
      printerDone: () => this.printerFinished(),
    };
    //this.addStripeTerminal();
    if (window.Android && window.Android.addStripeTerminal) {
      window.Android.addStripeTerminal();
    }
  },

  methods: {
    ...mapActions(["updateSettings"]),

    // scanReaders() {
    // window.Android.scanForReaders();
    // },

    isAndroid() {
      return /Android/i.test(navigator.userAgent);
    },
    isIOS() {
      return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    },

    // Unified function to initiate reader discovery based on platform
    initiateReaderDiscovery() {
      if (this.isAndroid()) {
        this.scanReaders(); // Call the Android-specific method
      } else if (this.isIOS()) {
        this.discoverReaders(); // Call the iOS-specific method
      } else {
        console.log("Platform not supported");
      }
    },
    // Android-specific method
    scanReaders() {
      if (window.Android && typeof window.Android.scanForReaders === "function") {
        window.Android.scanForReaders();
      } else {
        console.log("Android interface not available");
      }
    },
    // iOS-specific method
    discoverReaders() {
      if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.stripeHandler) {
        window.webkit.messageHandlers.stripeHandler.postMessage({
          action: "discoverReaders",
          additionalData: { note: "This is a test message" },
        });
      } else {
        console.log("iOS message handler not available");
      }
    },

    connectReader() {
      if(window.Android && window.Android.connectReader){
        window.Android.connectReader();
      }
    },

    searchForPrinter() {
      if ("Android" in window) {
        this.loadingPrinters = true;
        window.Android.searchForPrinter();
      }
      console.log("No android");
    },

    listPrinters(printers) {
      if (printers == []) {
        this.printerNotFound = true;
      } else {
        this.printerFound = true;
        this.printers = printers;
        this.loadingPrinters = false;
      }
    },

    printerFinished() {
      console.log("printed");
      this.printerDone = true;
    },

    savePrinter() {
      let label = document.getElementById("labels");
      if (label.value == "Select...") {
        this.labelError.error = true;
        this.labelError.not = "select a label size";
        return;
      } else {
        console.log(this.printer);
        this.printer.label = label.value;
        window.localStorage.setItem("printer", JSON.stringify(this.printer));
        this.printers = [];
        this.labelError.error = false;
      }
    },

    newPrinter(event) {
      let p = event.target.value.split(",");
      this.printer.ip = p[0];
      this.printer.model = p[1].replace("-", "_");
      this.printer.label = "";
      this.printerSelected = true;
      console.log("new printer added", event, this.printer);
    },

    addStripeTerminal() {
      this.channel.push("stripe:terminal:token", { location: "tml_FyETuAUnbTto1R" }).receive("ok", async (reply) => {
        console.log(reply);
        window.Android.addStripeTerminal(JSON.stringify(reply));
      });
    },

    saveFloat() {
      //let setting = JSON.parse(localStorage.getItem("localSettings"));
      //setting[0].float = this.float;
      //localStorage.setItem("localSettings", JSON.stringify(setting));
    },

    addDeliveryOption() {
      if (this.newDelivery) {
        this.siteSettings.delivery_options.push(this.newDelivery);
        this.newDelivery = "";
        this.saveSiteSettings();
      }
    },

    removeDeliveryOption(idx) {
      this.siteSettings.delivery_options.splice(idx, 1);
      this.saveSiteSettings();
      console.log(this.siteSettings);
    },

    saveEFTPOSSettings() {
      let stations = window.localStorage.getItem("stations");
      stations = JSON.parse(stations);
      // stations.find(
      //   (station) =>
      //     station.name == this.localSettings[0].name &&
      //     station.site == this.localSettings[0].site
      // ).eftpos = this.localSettings[0].eftpos;
      localStorage.setItem("localSettings", JSON.stringify(this.localSettings));
      let newStations = stations.map((station) => {
        if (station.site == this.localSettings[0].site && station.name == this.localSettings[0].name) {
          return JSON.parse(localStorage.getItem("localSettings"))[0];
        } else {
          return station;
        }
      });
      this.saveStationSettings(newStations);
    },

    saveStationSettings(stations) {
      this.save_pos_btn_text = "Saving";
      this.channel
        .push("company:pos_station:update", stations)
        .receive("ok", () => {
          this.save_pos_btn_text = "Saved";
        }) //console.log("saved", reply)
        .receive("error", () => {
          this.save_pos_btn_text = "Error";
        });
    },

    saveSiteSettings() {
      this.save_btn_text = "Saving";
      this.channel
        .push("company:site:update", this.siteSettings)
        .receive("ok", () => {
          this.save_btn_text = "Saved";
        })
        .receive("error", () => {
          this.save_btn_text = "Error";
        });
    },

    openCloseSite() {
      this.channel.push("company:site:close", { status: this.getClosed });
    },

    setYear(year) {
      // console.log("changed Year to ", year, this.currentYear);
      if (this.thisYear.toString() == year.toString()) {
        this.holidays = holidays2024;
      } else {
        this.holidays = holidays2025;
      }
      this.currentYear = year;
    },

    saveOpeningHours() {
      this.siteSettings.hours = {
        holiday: this.holidayFinal,
        week: this.weekFinal,
      };
      this.updateSettings([this.siteSettings, this.localSettings]);
      this.saveSiteSettings();
    },

    logoutCompanion() {
      this.channel.push("companion:logout", this.station);
    },
  },
  watch: {
    selectedRegion() {
      let matchedRegion = this.getRegions.filter((holiday) => holiday.HolidayName.includes(this.selectedRegion));
      this.holidayFinal.forEach((h) => {
        if ("regional" in h && h.regional) {
          h.name = matchedRegion[0].HolidayName;
          h.date = matchedRegion[0].ActualDate;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "public/layout";
@import "public/wrapper";
@import "public/actions";

.eftpos {
  grid-column: 1 / 3;
}

.blue {
  color: var(--action-colour);
}

.red {
  color: red;
}
.loading {
  position: fixed;
  top: 0;
  left: 0;
  background: var(--opacity-background);
}

li {
  p {
    margin: 0.5rem;
  }
  .del-option {
    text-transform: capitalize;
  }
}

.printer-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem;
  padding: 1rem;
  background-color: var(--secondary-colour);
  border-radius: 0.5rem;
  .find-printer {
    display: flex;
    flex-direction: column;
    p {
      width: 10rem;
      margin: 0.5rem;
      text-align: center;
    }
  }
  .selected-printer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 1rem;
    margin: 0.5rem;
    background-color: var(--primary-colour);
    border-radius: 0.5rem;
    .cur-printer,
    .label {
      p {
        margin: 0.25rem;
      }
    }
  }
  .printer-list {
    display: inherit;
    .printer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 1rem;
      margin: 0.5rem;
      background-color: var(--primary-colour);
      border-radius: 0.5rem;
      cursor: pointer;
      transition: ease-in-out 500ms;
    }
  }
  .btn {
    height: 3rem;
    width: 10rem;
    background-color: var(--action-colour);
    color: var(--secondary-text-colour);
    border: none;
    border-radius: 0.25rem;
    padding: 1rem;
    margin: 0.5rem;
    cursor: pointer;
    transition: ease-in-out 500ms;
    &:hover {
      background-color: var(--banner-colour);
      color: var(--alt-text-colour);
    }
  }
}

.float {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.hours-btn {
  background-color: var(--secondary-colour);
  color: var(--banner-colour);
  border: none;
  border-radius: 5px;
  padding: 2.5rem;
  margin: 0.5rem;
  cursor: pointer;
  transition: ease-in-out 500ms;
  border: 3px solid var(--banner-colour);
  &:hover {
    background-color: var(--banner-colour);
    color: var(--alt-text-colour);
  }
}

.save-printer {
  background-color: var(--action-colour);
  color: var(--secondary-text-colour);
  border: none;
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 0.5rem;
  cursor: pointer;
  transition: ease-in-out 500ms;
  border: 3px solid var(--banner-colour);
  &:hover {
    background-color: var(--banner-colour);
    color: var(--alt-text-colour);
  }
}

form {
  label {
    display: contents;
  }
}
.toggle {
  --toggle-width: 12rem;
  --toggle-border-off: black;
  --toggle-border-on: black;
  --toggle-bg-on: var(--action-colour);
  --toggle-border: 2px;
  --toggle-height: 48px;
  --toggle-font-size: 1rem;
  outline-color: green;
  outline: 0;
  border-color: var(--action-colour);
  border-left-width: 1px;
  border-right-width: 1px;
}
.dropdown {
  width: 100%;
  border: 1px solid var(--action-colour);
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: var(--secondary-colour);
  color: var(--text-colour);
  font-size: 1rem;
  margin: 0.5rem;
}

.timePicker {
  input {
    width: 50%;
    border: 1px solid var(--action-colour);
    border-radius: 0.5rem;
    padding: 0.5rem;
    background-color: var(--secondary-colour);
    color: var(--text-colour);
    font-size: 1rem;
    margin: 0.5rem;
  }
}
.details {
  align-items: flex-start;
  p {
    margin: 3px;
    text-align: left;
  }
}

@media only screen and (orientation: portrait) {
  .toggle {
    --toggle-width: 7rem;
  }
  .hours-btn {
    padding: 1rem;
  }
}
</style>
