<template>
  <div class="tri-toggle">
    <button @click="theme('light')" class="radio" id="light" name="os" :class="selected === 'light' ? 'visible' : 'hide'">
      <font-awesome-icon icon="sun" />
    </button>
    <button checked @click="theme('os')" class="radio" id="os" name="os" :class="selected === 'os' ? 'visible' : 'hide'">
      <font-awesome-icon icon="circle-half-stroke" />
    </button>
    <button @click="theme('dark')" class="radio" id="dark" name="os" :class="selected === 'dark' ? 'visible' : 'hide'">
      <font-awesome-icon icon="moon" />
    </button>
  </div>
</template>
<script>
export default {
  name: "Theme",
  data() {
    return {
      darkMode: false,
      lightMode: false,
      osDefault: true,
      selected: "os", //hardcoded?
    };
  },
  mounted() {
    if (document.documentElement.classList.contains("dark-theme")) {
      document.getElementById("dark").checked = true;
    }
    if (document.documentElement.classList.contains("light-theme")) {
      document.getElementById("light").checked = true;
    }
  },
  methods: {
    theme(name) {
      document.documentElement.classList.remove("light-theme");
      document.documentElement.classList.remove("dark-theme");
      this.selected = name;

      if (name !== "os") {
        document.documentElement.classList.add(`${name}-theme`);
        document.getElementById(`${name}`).checked = true;
      } else {
        document.getElementById("os").checked = true;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.tri-toggle {
  display: flex;
  border: 2px solid black;
  justify-content: space-evenly;
  align-items: center;
  width: 8rem;
  padding: 0.25rem;
  border-radius: 50px;

  .radio {
    display: block;
    padding: 0;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: none;
    margin: 0;

    transition: all 1s ease-in-out;
  }

  .visible {
    opacity: 1;
  }

  .hide {
    opacity: 0.4;
  }
}
</style>
